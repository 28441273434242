<template>
	<main>
		<h1>Öppna kundvagn</h1>

        <GetCartForm @selected="selected" />
	</main>
</template>

<script>
import cartsLocalStorage from '../../home/cartsLocalStorage'
import GetCartForm from '../components/GetCartForm'

export default {
    name: 'GetCart',
    components: {
        GetCartForm,
    },
    methods: {
        selected(cart) {
            cartsLocalStorage.save(cart.id);

            this.$router.push('/');
        },
    },
};
</script>
